import { reactive } from 'vue'
import { getFrontConfig, updateFrontConfig } from '@/apis/layout'

export type Option = {
  splitMenus: boolean;
  title: string;
  titleIcon: string;
  navTheme: 'light' | 'dark';
  layout: string;
  fixSiderbar: boolean;
  primaryColor: string;
  get: () => void;
  menuData: any[];
  update: (current: any) => void;
}
export const layoutState = reactive<Option>({
  splitMenus: true,
  title: 'Jetlinks',
  titleIcon: 'http://wteam-gc.oss-cn-hangzhou.aliyuncs.com/Logo-%E9%80%8F%E6%98%8E.png',
  navTheme: 'light',
  layout: 'side',
  fixSiderbar: true,
  primaryColor: '#1890ff',
  menuData: [],
  get () {
     //设置网站logo和标题
     let data: any = localStorage.getItem('loginInfo')
    if (data){
      data = JSON.parse(typeof data === 'string' ? data : '')
      // @ts-ignore
      document.querySelector('link[rel="icon"]').href = this.titleIcon = data.logo
      document.title = this.title =  data.systemName
    }

/*    getFrontConfig().then(resp => {
      if (resp.data.code === 200) {
        const data = { ...resp.data.result }
        this.titleIcon = data.titleIcon
        this.navTheme = data.navTheme
        this.fixSiderbar = data.fixSiderbar
        this.title = data.title
        this.primaryColor = data.primaryColor
      }
    })*/
  },
  update (data: any) {
    this.get()
    updateFrontConfig(data).then(resp => {
      if (resp.data.code === 200) {
        this.get()
      }
    })
  }
})
