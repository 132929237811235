import { createStore } from 'vuex'
import user, { userState } from '@/store/modules/user'

export interface State {
  user: userState
  identity: any
  count: number
}

const store = createStore({
  modules: {
    user
  }
})

export default store
/*
// App.tsx
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { State } from './store'


export default defineComponent({
  name: 'App',
  setup () {
    const { state: { test }, getters, commit } = useStore<State>()
    const addUser = () => {
      commit('test/ADD_USER', { name: 'haha' })
    }
    return () => {
      const items = test.users.map(user => (
        <li>{ user.name }</li>
      ))
      return (
        <div>
          <div>用户总数: { getters['test/userCount'] }</div>
      <ul>{ items }</ul>
      <button onClick={ addUser }>添加</button>
        </div>
    )
    }
  }
})
*/
