import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// @ts-ignore
import store from './store'
import axios from 'axios'
import Antd from 'ant-design-vue'
import icons from '@/utils/icons.ts'
import moment from 'moment'
import 'ant-design-vue/dist/antd.css'
import '@ant-design-vue/pro-layout/dist/style.css'
import ProLayout, { PageContainer } from '@ant-design-vue/pro-layout'
// @ts-ignore
import JsonViewer from "vue3-json-viewer"
import "vue3-json-viewer/dist/index.css";
//防止localstorage被修改
window.addEventListener('storage', function (e) {
  localStorage.setItem(typeof e.key === 'string' ? e.key : '', typeof e.oldValue === 'string' ? e.oldValue : '')
});
const app = createApp(App as any)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$moment = moment
app.use(JsonViewer)
app.use(store)
app.use(router)
app.use(Antd)
app.use(icons)
app.use(ProLayout)
app.use(PageContainer)
app.mount('#app')
