
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons-vue'
import { getMenuData, clearMenuItem } from '@ant-design-vue/pro-layout'
import type { RouteContextProps } from '@ant-design-vue/pro-layout'
import { layoutState } from './layout'
import { initWebSocket } from '@/utils/websocket'
import router from '@/router'
import { forLogo, updatePassword } from '@/apis/login'
import { message } from 'ant-design-vue'
const i18n = (t: string) => t
export default defineComponent({
  name: 'BasicLayout',
  components: {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined
  },

  setup () {
    initWebSocket()
    const changeVisible = ()=>{
      visible.value = true
    }
    const visible = ref(false)
    const handleOk = ()=>{
      updatePassword(formState.value).then((resp)=>{
        if (resp.data.code === 20001){
          message.success('修改密码成功,请重新登录')
          logOut()
        }
      })
      formState.value.password = ''
      formState.value.newPassword = ''
      visible.value = false
    }
    const handleCancel = ()=>{
      formState.value.password = ''
      formState.value.newPassword = ''
      visible.value = false
    }
    const formState = ref({
      password: "",
      newPassword: "",
    })

    let identity = localStorage.getItem('identity')
    const loading = ref(false)
    let userDetail = JSON.parse(localStorage.getItem('userInfo') || '{}')
    userDetail = userDetail ?  userDetail : {}
    const router = useRouter()
    const { menuData } = getMenuData(clearMenuItem(router.getRoutes()))
    const baseState = reactive<Omit<RouteContextProps, 'menuData'>>({
      selectedKeys: [],
      openKeys: [],
      collapsed: false,
      primaryColor: '#1890ff'
    })
    const breadcrumb = computed(() =>
      router.currentRoute.value.matched.concat().map(item => {
        return {
          path: item.path,
          breadcrumbName: item.meta.title || ''
        }
      })
    )
    const handleCollapsed = () => {
      baseState.collapsed = !baseState.collapsed
    }
    const logOut = () => {
      localStorage.setItem('x-access-token','')
      router.replace('/login')
    }
    const toSystem = () => {
      router.push('/config')
     }
     const toCenter = () => {
      router.push('/center')
     }
    watchEffect(() => {
      forLogo().then((resp) => {
        if (resp.data.code == 200) {
          let data = JSON.parse(resp.data.result)
          localStorage.setItem('loginInfo',resp.data.result)
          localStorage.setItem('logo',data.logo)
          localStorage.setItem('title',data.systemName)
          // @ts-ignore
          document.querySelector('link[rel="icon"]').href  = data.logo
          document.title = data.systemName
        }
      });
      layoutState.menuData = menuData
      // 获取前端信息
      layoutState.get()
      if (router.currentRoute) {
        const matched = router.currentRoute.value.matched.concat()
        baseState.selectedKeys = matched.filter(r => r.name !== 'index').map(r => r.path)
        baseState.openKeys = matched
          .filter(r => r.path !== router.currentRoute.value.path)
          .map(r => r.path)
      }
    })
    return {
      i18n,
      userDetail,
      baseState,
      layoutState,
      loading,
      breadcrumb,
      handleCollapsed,
      logOut,
      toSystem,
      identity,
      toCenter,
      handleOk,
      handleCancel,
      visible,
      changeVisible,
      formState,
    }
  }
})
