// store/modules/test.ts
import { Module } from 'vuex'

export interface User {
  id: string
  avatar: string
  username: string
  password: string
  logo: string
  status: string
  phone: string
}

export interface userState {
  user: User
  identity: any
  count: number
}
export default {
  // 需要存储的值都放在这里面
  state: {
    user: {
      avatar: '',
      id: '',
      logo: '',
      password: '',
      phone: '',
      status: '',
      username: '',
    },
    identity: localStorage.getItem('identity'),
    count: 0,
  },
  // 相当于组件的计算属性 通过 $store.getters.doubleCount 获取计算后的值
  getters: {
    getUser (state) {
      return state.user
    },
    getIdentity (state) {
      return state.identity
    },
  },
  // 在其他视图中通过 $store.commit('setState', 10) 使用，用于修改stor存的值
  mutations: {
    ADD_USER (state, user: User) { // 只能接受两个参数，用于修改store存的值
      state.user = user
    },
    increment(state) {
      state.count++
    }
  },
  // 异步任务 不会改变state 通过 $store.dispath('doubleCount') 使用
  actions: {
    doubleCount(context) {
      context.commit("doubleCount");
    },
  },
  // store的下级store 方便大型项目复杂数据管理，这里面相当于可以在放置一个和外面这些一样的模块
  modules: {},
  namespaced: true,
  strict: true
} as Module<userState, Object>
