import Axios from '@/utils/axios'
import { baseUrl } from '@/utils/SL-service'
/**
 * 获取前端配置
 */
export function getFrontConfig () {
  return Axios({
    url: `${baseUrl}/system/config/front`,
    method: 'GET'
  })
}

/**
 * 修改前端配置
 */
export function updateFrontConfig (data: any) {
  return Axios({
    url: `${baseUrl}/system/config/front`,
    method: 'POST',
    data
  })
}
/**
 * 获取用户信息和权限
 */
export function getAuthorizeInfo () {
  return Axios({
    url: `${baseUrl}/authorize/me`,
    method: 'GET'
  })
}
