import Axios from '@/utils/axios'
import { baseUrl } from '@/utils/base-service'
const baseUrlSL = '/SL'
/**
 * 验证是否需要验证码
 */
export function isCaptchaConfig () {
  return Axios({
    url: `${baseUrl}/authorize/captcha/config`,
    method: 'GET'
  })
}

/**
 * 获取验证码
 */
export function getCaptcha (params: {width: number, height: number}) {
  return Axios({
    url: `${baseUrl}/authorize/captcha/image`,
    method: 'GET',
    params
  })
}

/**
 * 登录
 */
export function login (data: any) {
  return Axios({
    url: `${baseUrl}/staff/admin/login`,
    method: 'POST',
    data
  })
}
/**
 * 登录
 */
export function getToken () {
  let data = {
    username: 'admin',
    password: 'admin',
  }
  return Axios({
    url: `${baseUrlSL}/authorize/login`,
    method: 'POST',
    data
  })

}
/**
 * 获取logo
 */
export function forLogo () {
  return Axios({
    url: `${baseUrl}/staff/admin/logo`,
    method: 'GET',
  })
}
/**
 * 获取用户信息
 */
export function getUserInfo () {
  return Axios({
    url: `${baseUrl}/staff/admin/getUserInfo`,
    method: 'GET'
  })
}
/**
 * 获取个人中心信息
 */
export function getUserCenter () {
  return Axios({
    url: `${baseUrl}/staff/admin/UserCenter`,
    method: 'POST'
  })
}

/**
 * 获取jetlinks版本
 */
export function getSystemVersion () {
  return Axios({
    url: `${baseUrl}/system/version`,
    method: 'GET'
  })
}

/**
 * 获取系统配置
 */
export function getConfig (params: any) {

  return Axios({
    url: `${baseUrl}/manager/setting/get/${params}`,
    method: 'GET'
  })
}
/**
 * 更新系统配置
 */
export function updateConfig (params: any, data: any) {

  return Axios({
    url: `${baseUrl}/manager/setting/put/${params}`,
    method: 'PUT',
    data
  })
}
/**
 * 修改密码
 */
export function updatePassword (params: any) {
  return Axios({
    url: `${baseUrl}/staff/admin/editPassword`,
    method: 'PUT',
    params
  })
}
