
import { defineComponent } from 'vue'
import 'animate.css';
// import 'three-dots';
export default defineComponent({
    name: 'App',
  setup () {
    const getPopupContainer = (el: Element, dialogContext: any) => {
      if (dialogContext) {
        return dialogContext.getDialogWrap()
      }
      return document.body
    }
    return {
      getPopupContainer
    }
  }
})
