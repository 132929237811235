import { Token } from '@/utils/utils'
import { message, notification } from 'ant-design-vue'
import { getToken } from '@/apis/login'
let ws: WebSocket | undefined
let timer: any
let count = 0
let subs: any
const initWebSocket = () => {
  clearInterval(timer)
  // var ws = new WebSocket("ws://114.132.229.139:8848/messaging/a872d8e6cf6ccd38deb0c8772f6040e3");
  let token = localStorage.getItem('token')
  const wsUrl = `ws://${document.location.host}/SL/messaging/${token}?:X_Access_Token=${token}`
  // const wsUrl = `ws://${document.location.host}//SL/messaging/${Token.get()}?:X_Access_Token=${Token.get()}`
  // const wsUrl = `ws:demo.jetlinks.cn/SL/messaging/${Token.get()}?:X_Access_Token=${Token.get()}`
  // const wsUrl = `ws://114.132.229.139:8848`
  if (!ws && count < 5) {
    try {
      count += 1
      ws = new WebSocket(wsUrl)
      ws.onopen = (e) => {
        timer = setInterval(() => {
          try {
            ws && ws.send(JSON.stringify({ type: 'ping' }))
          } catch (error) {
            console.error(error, '发送心跳错误')
          }
        }, 2000)
      }
      ws.onclose = (e) => { // 关闭
        ws = undefined
        setTimeout(initWebSocket, 20000 * count)
      }
      ws.onmessage = (e) => { // 接收数据
        const data = JSON.parse(e.data)
        if (data.message === '认证失败'){
          //  获取114接口的登录token（用于websocket）
          getToken().then((resp) => {
            if (resp.data?.status === 200) {
              localStorage.setItem('token',resp.data.result.token)
            }
          });
        }
        if (data.type === 'error') {
          notification.error({ key: 'ws-err', message: data.message })
        }
        if (subs && subs[data.requestId]) {
          subs[data.requestId](data)
        }
      }
    } catch (error) {
      console.log('ws发生错误',error)
      setTimeout(initWebSocket, 5000 * count)
    }
  }
  return ws
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getWebsocket = (agentData: { id: string, topic: string, parameter: any, type: 'sub' | 'unsub' | 'cpu' }, callback: any) => {
  if (!subs) {
    subs = {}
  }
  if (callback) {
    if (typeof callback === 'function') {
      subs[agentData.id] = callback
    } else {
      subs[agentData.id] = {}
    }
  }
  const thisWs = initWebSocket()
  const tempQueue: any[] = []
  if (thisWs) {
    try {
      if (thisWs.readyState === 1) {
        thisWs.send(JSON.stringify(agentData))
      } else {
        tempQueue.push(JSON.stringify(agentData))
      }
      if (tempQueue.length > 0) {
        if (thisWs.readyState === 1) {
          tempQueue.forEach((i: any, index: number) => {
            console.log('send',i)
            thisWs.send(i)
            tempQueue.splice(index, 1)
          })
        } else {
          setTimeout(() => {
            tempQueue.forEach((i: any, index: number) => {
              console.log('send',i)
              thisWs.send(i)
              tempQueue.splice(index, 1)
            })
          }, 2000)
        }
      }
    } catch (error) {
      initWebSocket()
      message.error({ key: 'ws', content: 'websocket服务连接失败' })
    }
  }
}

export { getWebsocket, initWebSocket }
